// FIXME 這邊目前沒在用了

import { Controller } from "stimulus"
import { get, post, put, patch, destroy } from '@rails/request.js'
import { trackingEvent } from "components/tracking_event";
import * as Sentry from "@sentry/browser";

export default class extends Controller {
  static values = {
    finalPrice: Number
  }
  cartItems = []

  async connect() {
    // get post info for ga
    const resp = await get('/api/v1/cart/cart_items')
    if(resp.ok){
      const res = await resp.json
      this.cartItems = res.result
      const eventData = {
        finalPrice: this.finalPriceValue,
        items: this.cartItems
      }
      trackingEvent.view_cart(eventData)
      sessionStorage.setItem("cartEventInfo", JSON.stringify(eventData))
    } else {
      const res = await resp.text
      Sentry.captureException(res)
      sessionStorage.removeItem("cartEventInfo");
    }
  }

  async remove_from_cart(e){
    e.preventDefault()
    // 先發送remove event再執行remove
    const itemId = e.currentTarget.dataset.itemId
    const postInfoIndex = this.cartItems.findIndex(item => item.id == itemId)
    const postInfo = this.cartItems[postInfoIndex]
    trackingEvent.remove_from_cart(postInfo)

    const resp = await destroy(
      '/cart_items', 
      { 
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({ id: itemId })
      }
    )
    if(resp.ok){
      const res = await resp.json
      const status = res['status'] || 'empty' 
      if(status != 'ok') {
        Sentry.captureException(res.errors)
      } else {
        location.reload()
      }
    } else {
      const res = await resp.text
      Sentry.captureException(res)
    }
  }

}
